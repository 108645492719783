import { useState } from "react";
import moment from "moment";

const useDateRange = (
  initialDates,
  search,
  setSearch,
  dateFieldNames = { from: "listDateFrom", to: "listDateTo" },
  dateFormat,
) => {
  const [selectedDates, setSelectedDates] = useState(initialDates);

  const handleDateChange = (dates) => {
    setSelectedDates(dates);

    if (dates.length === 2) {
      const startDate = moment(dates[0]).startOf("day");
      const endDate = moment(dates[1]).endOf("day");
      setSearch((prevSearch) => ({
        ...prevSearch,
        [dateFieldNames.from]: startDate.format(dateFormat),
        [dateFieldNames.to]: endDate.format(dateFormat),
      }));
    } else if (dates.length === 1) {
      const date = moment(dates[0]);
      setSearch((prevSearch) => ({
        ...prevSearch,
        [dateFieldNames.from]: date.startOf("day").format(dateFormat),
        [dateFieldNames.to]: date.endOf("day").format(dateFormat),
      }));
    } else {
      setSearch((prevSearch) => ({
        ...prevSearch,
        [dateFieldNames.from]: null,
        [dateFieldNames.to]: null,
      }));
    }
  };

  return { selectedDates, handleDateChange };
};

export default useDateRange;
