import {
  Badge,
  Flex,
  IconButton,
  Image,
  Tooltip,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import { banks } from "../../bank";
import { HiOutlineHand, HiOutlineKey, HiOutlineRefresh } from "react-icons/hi";
import {
  HiOutlineMinus,
  HiOutlinePencil,
  HiOutlinePlus,
  HiOutlineSquare2Stack,
} from "react-icons/hi2";
import { formatDate } from "../../../utils/DateformatUtils";
import { useHistory } from "react-router-dom";
import { getMemberLevelColorScheme } from "../../../utils/CommonUtils";

export const membersColumnsData = (handleModalOpen, handleRefreshCredit) => {
  return [
    {
      Header: "ระดับ",
      accessor: "memberLevelName",
      Cell: ({ cell }) => (
        <Flex align="center" justify="center">
          <Badge
            colorScheme={getMemberLevelColorScheme(cell.value)}
            w="65px"
            h="28px"
            borderRadius="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {cell.value ? cell.value : "-"}
          </Badge>
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: "ยูสเซอร์",
      accessor: "username",
      Cell: ({ row }) => {
        const history = useHistory();
        const { onCopy } = useClipboard(row.original.username);

        return (
          <Flex align="center">
            <IconButton
              icon={<HiOutlineSquare2Stack />}
              onClick={onCopy}
              aria-label="Copy link"
              variant="outline"
              size="sm"
              border="none"
              mr={2}
            />
            <Text
              color={row.original.memberTagsColor}
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
              onClick={() =>
                history.push(`/admin/member/${row.original.username}`)
              }
            >
              {row.original.username}
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: "เบอร์โทรศัพท์",
      accessor: "tel",
    },
    {
      Header: "ชื่อ-นามสกุล",
      accessor: "fullName",
      disableSortBy: true,
      isSorted: false,
    },
    {
      Header: "ธนาคาร",
      accessor: "ธนาคาร",
      Cell: ({ cell, row }) => (
        <Flex align="center" justify="center" w="100%">
          <Image
            src={`${banks[row.original.bankName]?.logo}`}
            alt={cell.value}
            backgroundColor={banks[row.original.bankName]?.color}
            boxSize="30px"
            borderRadius="50%"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
          />
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: "เลขบัญชี",
      accessor: "accountNumber",
    },
    {
      Header: "เครดิต",
      accessor: "credit",
      Cell: ({ value, row }) => {
        const refreshCredit = () => {
          handleRefreshCredit(row.original.username);
        };

        return (
          <Flex alignItems="center" justifyContent="flex-end">
            <span>{value.toFixed(2)}</span>
            <Tooltip>
              <IconButton
                ml={2}
                size="xs"
                icon={<HiOutlineRefresh />}
                aria-label="Refresh credit"
                onClick={refreshCredit}
                isRound
                variant="outline"
                borderColor={"green.500"}
                color={"green.500"}
              />
            </Tooltip>
          </Flex>
        );
      },
      disableSortBy: true,
    },
    {
      Header: "ฝากมือ",
      Cell: ({ row }) => (
        <IconButton
          variant="outline"
          colorScheme="teal"
          aria-label="deposit hand"
          size="sm"
          icon={<HiOutlineHand />}
          onClick={() => handleModalOpen("addCreditModal", row.original)}
        />
      ),
      disableSortBy: true,
    },
    {
      Header: "ฝากด้วยสลิป",
      Cell: ({ row }) => (
        <IconButton
          variant="outline"
          colorScheme="orange"
          aria-label="deposit slip"
          size="sm"
          icon={<HiOutlinePlus />}
          onClick={() => handleModalOpen("addCreditSlipModal", row.original)}
        />
      ),
      disableSortBy: true,
    },
    {
      Header: "ตัดเครดิต",
      Cell: ({ row }) => (
        <IconButton
          variant="outline"
          borderColor={"red.500"}
          color={"red.500"}
          aria-label="remove credit"
          size="sm"
          icon={<HiOutlineMinus />}
          onClick={() => handleModalOpen("removeCreditModal", row.original)}
        />
      ),
      disableSortBy: true,
    },
    {
      Header: "เปลี่ยนรหัสผ่าน",
      Cell: ({ row }) => (
        <IconButton
          variant="outline"
          colorScheme="blue"
          aria-label="remove credit"
          size="sm"
          icon={<HiOutlineKey />}
          onClick={() => handleModalOpen("resetPasswordModal", row.original)}
        />
      ),
      disableSortBy: true,
    },
    {
      Header: "สถานะโปรโมชั่น",
      accessor: "isBonus",
      Cell: ({ cell }) => (
        <Flex align="center" justify="center">
          <Badge
            colorScheme={cell.value === true ? "green" : "red"}
            // backgroundColor="transparent"
            // border="1px"
            // borderColor={cell.value === true ? 'green.500' : 'red.500'}
            // color={cell.value === true ? 'green.500' : 'red.500'}
            w="90px"
            h="28px"
            borderRadius="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {cell.value === true ? "รับโปรโมชั่น" : "ไม่รับโปรโมชั่น"}
          </Badge>
        </Flex>
      ),
    },
    {
      Header: "วันลงทะเบียน",
      accessor: "createdDate",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "IP",
      accessor: "ip",
      disableSortBy: true,
    },
    {
      Header: "แก้ไข",
      Cell: ({ row }) => (
        <IconButton
          variant="outline"
          borderColor={"green.500"}
          color={"green.500"}
          aria-label="edit"
          size="sm"
          icon={<HiOutlinePencil />}
          onClick={() => handleModalOpen("manageMemberModal", row.original)}
        />
      ),
      disableSortBy: true,
    },
  ];
};
