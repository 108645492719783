import { Box, Flex, Spacer, Spinner, Stack, Text } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import React, { useEffect, useState } from "react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import CardBody from "components/Card/CardBody";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { memberDepositColumnsData } from "variables/columns/member-report/memberDepositColumnsData";
import { getReferenceDates } from "utils/DateformatUtils";
import { fetchMemberDepositReport } from "services/member-report/memberDepositReportService.js";
import { mapJsonToTableData } from "utils/CommonUtils";
import useDateRange from "hooks/useDateRange";
import moment from "moment/moment";
import FileExportDropdown from "../../../components/FileExportDropdown/FileExportDropdown";

const MemberDepositReport = ({ pageName }) => {
  const queryClient = new QueryClient();
  const { dateNow, dateDayCheck } = getReferenceDates();

  const [search, setSearch] = useState({});
  const [downloadableData, setDownloadableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const { selectedDates, handleDateChange } = useDateRange(
    [dateDayCheck, dateNow],
    search,
    setSearch,
    dateFieldsNames,
    dateSearchFormat,
  );

  const fetchData = async (pageIndex, pageSize, search) => {
    const response = await fetchMemberDepositReport(
      pageIndex,
      pageSize,
      search,
    );
    const records = await fetchMemberDepositReport(pageIndex, 1000, search);
    const formattedRecords = mapJsonToTableData(
      records.results,
      memberDepositColumnsData(),
    );
    setDownloadableData(formattedRecords);
    return response;
  };

  useEffect(() => {
    setSearch({
      [dateFieldsNames.from]: moment(dateNow).format(dateSearchFormat),
      [dateFieldsNames.to]: moment(dateDayCheck).format(dateSearchFormat),
    });
    setIsLoading(false);
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Box>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Box>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" mb="6px">
                  รายงานสมาชิกที่มีการฝาก
                </Text>
              </Flex>
              <Spacer />
              <FileExportDropdown
                data={downloadableData}
                filename={"รายงานสมาชิกที่มีการฝาก"}
              />
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <Flex direction="column" align="flex-end" mb="24px" w="100%">
                  <Box width="100%">
                    <QueryClientProvider client={queryClient}>
                      <SearchTableRemote
                        columnsData={memberDepositColumnsData()}
                        fetchData={fetchData}
                        search={search}
                        setSearch={setSearch}
                      />
                    </QueryClientProvider>
                  </Box>
                </Flex>
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default MemberDepositReport;
