import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Grid,
  GridItem,
  Text,
  HStack,
  useBreakpointValue,
  Stack,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";

import { BANK_MAP, ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import { validateField } from "../../../../utils/CommonUtils";

import { fetchMemberLevel } from "services/member-management/memberLevelService";
import {
  addBankSetting,
  updateBankSetting,
} from "services/finance-setting/bankSettingService";
import { fetchBanks } from "services/member-management/memberService";
import { useSuccessHandler } from "hooks/useSuccessHandler";

const ManageBankModal = ({ isOpen, onClose, bank = null, onSuccess }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [banks, setBanks] = useState([]);
  const [memberLevels, setMemberLevels] = useState([]);

  const modalSize = useBreakpointValue({ base: "full", md: "2xl" });

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const handleSaveMember = (values) => {
    if (values.id) {
      updateBank(values.id, values);
    } else {
      insertBank(values);
    }
    onClose();
  };

  const insertBank = (obj) => {
    addBankSetting(obj)
      .then((response) => {
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        onSuccess();
      });
  };
  const updateBank = (id, obj) => {
    updateBankSetting(id, { ...obj, bankName: obj.bankCode })
      .then((response) => {
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        onSuccess();
      });
  };

  const fetchBanksData = (type, nationality) => {
    if (type === "DEPOSIT") {
      fetchBanks("bankDeposit", nationality)
        .then((response) => {
          setBanks(response.data.data);
        })
        .catch((error) => {
          handleError(error, ERROR_MESSAGE);
        });
    } else {
      fetchBanks("bankWithdraw", nationality)
        .then((response) => {
          setBanks(response.data.data);
        })
        .catch((error) => {
          handleError(error, ERROR_MESSAGE);
        });
    }
  };

  useEffect(() => {
    fetchMemberLevel()
      .then((response) => {
        setMemberLevels(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  }, []);

  useEffect(() => {
    if (bank) {
      fetchBanksData(bank?.bankType, bank?.nationality);
    }
  }, [bank]);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent
        maxH="100vh"
        p={8}
        borderRadius={{ base: "none", md: "md" }}
      >
        <ModalHeader>
          <Box textAlign="center">
            <Text fontSize="26px">{bank ? "แก้ไขธนาคาร" : "เพิ่มธนาคาร"}</Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Formik
            initialValues={{
              id: bank ? bank.id : "",
              bankCode: bank ? bank.bankCode : "",
              bankType: bank ? bank.bankType : "",
              bankName: bank ? bank.bankName : "",
              bankAccountName: bank ? bank.bankAccountName : "",
              bankAccountNo: bank ? bank.bankAccountNo : "",
              username: bank ? bank.username : "",
              password: bank ? bank.password : "",
              bankOrder: bank ? bank.bankOrder : "",
              bankGroup: bank ? bank.bankGroup : "",
              botIp: bank ? bank.botIp : "",
              newUserFlag: bank ? bank.newUserFlag : true,
              active: bank ? bank.active : true,
              version: bank ? bank.version : "",
              createdDate: bank ? bank.createdDate : "",
              updatedDate: bank ? bank.updatedDate : "",
              createdBy: bank ? bank.createdBy : "",
              updatedBy: bank ? bank.updatedBy : "",
              nationality: bank ? bank.nationality : "",
              currency: bank ? bank.currency : "",
              rate: bank ? bank.rate : "",
              descriptionImg: bank ? bank.descriptionImg : "",
              adminSubsidi: bank ? bank.adminSubsidi : "",
              adminFee: bank ? bank.adminFee : "",
              adminSubsidiPercentage: bank ? bank.adminSubsidiPercentage : "",
              adminFeePercentage: bank ? bank.adminFeePercentage : "",
              memberLevelId: bank ? bank.memberLevelId : null,
              minAmount: bank ? bank.minAmount : "",
              maxAmount: bank ? bank.maxAmount : "",
              serviceType: bank ? bank.serviceType : "",
              deviceId: bank ? bank.deviceId : "",
              pin: bank ? bank.pin : "",
              proxyIp: bank ? bank.proxyIp : "",
              hash: bank ? bank?.hash : "",
            }}
            onSubmit={(values, actions) => {
              handleSaveMember(values);
              actions.setSubmitting(false);
            }}
          >
            {(props) => (
              <Form>
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                  gap={4}
                >
                  <GridItem colSpan={{ base: 2, md: 2 }}>
                    <Field name="nationality" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.nationality && form.touched.nationality
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            สัญชาติ
                          </FormLabel>
                          <Select
                            {...field}
                            placeholder="เลือกสัญชาติ"
                            ref={initialRef}
                            fontSize="sm"
                          >
                            <option value="thai">ไทย</option>
                            <option value="laos">ลาว</option>
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 2 }}>
                    <Field name="bankType" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankType && form.touched.bankType
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ประเภทธนาคาร
                          </FormLabel>
                          <Select
                            {...field}
                            placeholder="ประเภทธนาคาร"
                            fontSize="sm"
                            onChange={(e) => {
                              const value = e.target.value;
                              props.setFieldValue("bankType", value);
                              fetchBanksData(value, props.values?.nationality);
                            }}
                          >
                            <option value="DEPOSIT">ฝาก</option>
                            <option value="WITHDRAW">ถอน</option>
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="bankCode">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankCode && form.touched.bankCode
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ธนาคาร
                          </FormLabel>
                          <Select
                            {...field}
                            placeholder="เลือกธนาคาร"
                            fontSize="sm"
                            onChange={(e) => {
                              const value = e.target.value;
                              props.setFieldValue("bankCode", value);
                              props.setFieldValue("bankName", BANK_MAP[value]);
                            }}
                          >
                            {banks.map((bank) => (
                              <option key={bank.id} value={bank.bankCode}>
                                {bank.bankName}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                    <GridItem colSpan={{ base: 2, md: 2 }}>
                      <Field name="bankName">
                        {({ field }) => (
                          <input {...field} style={{ display: "none" }} />
                        )}
                      </Field>
                    </GridItem>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="serviceType" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.serviceType && form.touched.serviceType
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ประเภทบริการ
                          </FormLabel>
                          <Select
                            {...field}
                            placeholder="ประเภทบริการ"
                            fontSize="sm"
                            onChange={(e) => {
                              const value = e.target.value;
                              props.setFieldValue("serviceType", value);
                              fetchBanksData(value, props.values?.nationality);
                            }}
                          >
                            <option value="MANUAL">Manual</option>
                            {props.values.bankCode === "014" && (
                              <option value="API">API</option>
                            )}
                            {props.values.bankType === "DEPOSIT" &&
                              props.values.bankCode === "014" && (
                                <option value="SCBCONNECT">SCB Connect</option>
                              )}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  {props.values.serviceType === "API" && (
                    <>
                      <GridItem colSpan={{ base: 2, md: 2 }}>
                        <Field name="deviceId" validate={validateField}>
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.deviceId && form.touched.deviceId
                              }
                              isRequired={!bank}
                            >
                              <FormLabel fontSize="xs" fontWeight="bold">
                                Device ID
                              </FormLabel>
                              <Input
                                {...field}
                                fontSize="sm"
                                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx"
                              />
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                      <GridItem colSpan={{ base: 2, md: 1 }}>
                        <Field name="pin" validate={validateField}>
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={form.errors.pin && form.touched.pin}
                              isRequired={!bank}
                            >
                              <FormLabel fontSize="xs" fontWeight="bold">
                                PIN
                              </FormLabel>
                              <Input
                                {...field}
                                fontSize="sm"
                                placeholder="xxxxxx"
                              />
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                      <GridItem colSpan={{ base: 2, md: 1 }}>
                        <Field name="proxyIp" validate={validateField}>
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.proxyIp && form.touched.proxyIp
                              }
                              isRequired={!bank}
                            >
                              <FormLabel fontSize="xs" fontWeight="bold">
                                ไอพี Proxy
                              </FormLabel>
                              <Input
                                {...field}
                                fontSize="sm"
                                placeholder="127.0.0.1"
                              />
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                    </>
                  )}
                  {props.values.serviceType === "SCBCONNECT" && (
                    <>
                      <GridItem colSpan={{ base: 2, md: 2 }}>
                        <Field name="hash" validate={validateField}>
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={form.errors.hash && form.touched.hash}
                              isRequired={!bank}
                            >
                              <FormLabel fontSize="xs" fontWeight="bold">
                                Hash
                              </FormLabel>
                              <Input
                                {...field}
                                fontSize="sm"
                                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx"
                              />
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                    </>
                  )}
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="bankAccountName" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankAccountName &&
                            form.touched.bankAccountName
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ชื่อ{" "}
                            <Box
                              as="span"
                              color="green.500"
                              fontWeight="normal"
                            >
                              (ตามสมุดบัญชี)
                            </Box>
                          </FormLabel>
                          <Input {...field} placeholder="ชื่อ" fontSize="sm" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="bankAccountNo" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankAccountNo &&
                            form.touched.bankAccountNo
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            เลขที่บัญชี
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="เลขที่บัญชี"
                            fontSize="sm"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="bankOrder" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankOrder && form.touched.bankOrder
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ลำดับ
                          </FormLabel>
                          <Input {...field} placeholder="ลำดับ" fontSize="sm" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>

                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="bankGroup" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankGroup && form.touched.bankGroup
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            กลุ่ม
                          </FormLabel>
                          <Input {...field} placeholder="กลุ่ม" fontSize="sm" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 2 }}>
                    <Field name="currency" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.currency && form.touched.currency
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            สกุลเงิน
                          </FormLabel>
                          <Select
                            {...field}
                            placeholder="เลือกสกุลเงิน"
                            fontSize="sm"
                          >
                            <option value="THB">THB</option>
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="rate" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.rate && form.touched.rate}
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            เรท
                          </FormLabel>
                          <Input {...field} placeholder="เรท" fontSize="sm" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="memberLevelId">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ระดับสมาชิก
                          </FormLabel>
                          <Select
                            {...field}
                            placeholder="ทั้งหมด"
                            fontSize="sm"
                          >
                            {memberLevels.map((obj) => (
                              <option key={obj.id} value={obj.id}>
                                {obj.levelName}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="minAmount" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.minAmount && form.touched.minAmount
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ยอดขั้นต่ำ
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="ยอดขั้นต่ำ"
                            fontSize="sm"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="maxAmount" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.maxAmount && form.touched.maxAmount
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ยอดสูงสุง
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="ยอดสูงสุง"
                            fontSize="sm"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Box mt={4}>
                      <HStack spacing={4}>
                        <Field name="newUserFlag">
                          {({ field }) => (
                            <FormControl display="flex" alignItems="center">
                              <Stack>
                                <FormLabel
                                  htmlFor="is-bonus-enabled"
                                  mb="0"
                                  fontSize="xs"
                                  fontWeight="bold"
                                >
                                  สำหรับยูสเซอร์ใหม่
                                </FormLabel>
                                <Switch
                                  id="is-bonus-enabled"
                                  {...field}
                                  isChecked={field.value}
                                />
                              </Stack>
                            </FormControl>
                          )}
                        </Field>
                        <Field name="active">
                          {({ field }) => (
                            <FormControl display="flex" alignItems="center">
                              <Stack>
                                <FormLabel
                                  htmlFor="is-no-bonus"
                                  mb="0"
                                  fontSize="xs"
                                  fontWeight="bold"
                                >
                                  สถานะเปิดใช้งาน
                                </FormLabel>
                                <Switch
                                  id="is-no-bonus"
                                  {...field}
                                  isChecked={field.value}
                                />
                              </Stack>
                            </FormControl>
                          )}
                        </Field>
                      </HStack>
                    </Box>
                  </GridItem>
                </Grid>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    backgroundColor="green.500"
                    color={"#000000"}
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageBankModal;
